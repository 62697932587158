import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

export const ButtonSimple = ({ children, to }) => {
  return (
    <Fragment>
      {to ? (
        <Link
          to={to}
          className="flex border-2 border-primary text-primary text-lg font-bold px-7 py-2 rounded-md sm:text-3xl sm:px-9 sm:py-3 lg:px-8 lg:py-2 lg:text-1.2vw 2xl:px-10 2xl:py-3 2xl:text-1.3vw transition-all duration-200 ease-out hover:text-dark hover:border-primary hover:bg-primary"
        >
          {children}
        </Link>
      ) : (
        <button className="flex border-2 border-primary text-primary text-lg font-bold px-7 py-2 rounded-md sm:text-3xl sm:px-9 sm:py-3 lg:px-8 lg:py-2 lg:text-1.2vw 2xl:px-10 2xl:py-3 2xl:text-1.3vw transition-all duration-200 ease-out hover:text-dark hover:border-primary hover:bg-primary">
          {children}
        </button>
      )}
    </Fragment>
  );
};
