import React from 'react';
import './Layout.scss';
import Footer from './Footer';
import Navbar from './Navbar';

const Layout = ({ children, active_nav }) => {
  return (
    <div className="mainLayout">
      <Navbar active_nav={active_nav} />
      <div className="mainLayout__children">{children}</div>
      <Footer active_nav={active_nav} />
    </div>
  );
};

export default Layout;
