import React from "react";
import "./NotFound.scss";
import Layout from "../components/Layout";
import { ButtonSimple } from "../components/Button";
import { Helmet } from "react-helmet-async";

const NotFound = () => {
  return (
    <>
      <Helmet>
        {/* <!-- robot jangan index --> */}
        <meta name="robots" content="noindex" />
        <meta name="googlebot" content="noindex" />
        <meta name="googlebot-news" content="noindex" />
        <meta name="googlebot-news" content="nosnippet" />
      </Helmet>

      <Layout>
        <div className="notfound text-light">
          <main className="notfound__main w-full flex flex-col items-center space-y-6 sm:space-y-10 lg:space-y-4 2xl:space-y-6">
            <h1 className="text-2xl font-bold text-center sm:text-4xl lg:text-2.1vw 2xl:text-2.3vw">
              Sorry, your page is not found.
            </h1>
            <div className="notfound__main-image">
              <img src="/images/universal/404-notfound.png" alt="Not Found" />
            </div>
            <ButtonSimple to="/">Go back home</ButtonSimple>
          </main>
        </div>
      </Layout>
    </>
  );
};

export default NotFound;
