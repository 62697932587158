import React from "react";
import "./LinkPage.scss";

import { motion } from "framer-motion";
import { FaInstagram, FaWhatsapp } from "react-icons/fa";
import { Helmet } from "react-helmet-async";

const LinkPage = () => {
  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://basmasesa.com/link" />
        <title>Links - Basma | Event, Exhibition & Interior</title>
        <meta
          name="description"
          content="Basmasesa is your one-stop event, exhibition & interior contractor for all your needs and wants. Build your imagine design and make it happen."
        />
        <meta
          name="keywords"
          content="events, exhibition, interior, kontraktor, contractor, booth, event booth, tenant, booth murah, property, pameran, stand booth"
        />
        <meta
          property="og:title"
          content="Home - Basma | Event, Exhibition & Interior"
        />
        <meta
          property="og:description"
          content="Basmasesa is your one-stop event, exhibition & interior contractor for all your needs and wants. Build your imagine design and make it happen."
        />
        <meta property="og:url" content="https://basmasesa.com/link" />
      </Helmet>
      <div className="linkPage">
        <div className="linkPage__body mx-auto w-[94%] lg:w-6/12 h-full py-10 flex flex-col space-y-6 lg:space-y-10 font-nunito-sans text-[#252525]">
          <div className="flex items-center justify-center w-44 h-44 md:w-52 md:h-52 lg:w-60 lg:h-60 mx-auto bg-white rounded-full">
            <img
              src="/images/basma-logo.png"
              alt="Logo"
              className="w-full h-auto object-contain"
            />
          </div>
          <div className="flex flex-col space-y-1 justify-center items-center text-center">
            <h1 className="text-xl sm:text-2.1xl lg:text-3xl font-bold">
              Basmasesa - Event, Exhibition & Interior
            </h1>
            <p className="text-sm sm:text-lg font-semibold">
              Build your imagine design and make it happen.
            </p>
          </div>
          <div className="w-full flex flex-col space-y-6">
            <a
              href="https://basmasesa.com"
              target="_blank"
              rel="noreferrer"
              className="relative flex w-full"
            >
              <motion.div
                whileHover={{
                  x: 2,
                  y: 3,
                }}
                className="relative flex items-center justify-center w-full p-5 text-sm sm:text-lg font-semibold bg-sky-50 rounded-full z-[10] shadow-xl"
              >
                <div className="absolute flex p-2 justify-center items-center h-11 w-11 sm:h-12 sm:w-12 rounded-full left-2 sm:left-3 absolute-y-center bg-amber-100">
                  <img
                    src="/images/basma-logo.png"
                    alt="Basmasesa"
                    className="w-auto h-full object-contain"
                  />
                </div>

                <p>Website Basmasesa</p>
              </motion.div>
              <div className="w-full h-full absolute bg-primary rounded-full top-[6px] lg:top-2 left-1 lg:left-[6px]"></div>
            </a>

            <a
              href="https://www.instagram.com/basmasesa.kreasi/"
              target="_blank"
              rel="noreferrer"
              className="relative flex"
            >
              <motion.div
                whileHover={{
                  x: 2,
                  y: 3,
                }}
                className="relative flex items-center justify-center w-full p-5 text-sm sm:text-lg font-semibold bg-sky-50 rounded-full z-[10] shadow-xl"
              >
                <div
                  className="absolute flex p-2 justify-center items-center h-11 w-11 sm:h-12 sm:w-12 rounded-full left-2 sm:left-3 absolute-y-center"
                  style={{
                    background:
                      "linear-gradient(163deg, #4964da 0%, rgba(146, 73, 221, 0.8) 11.8%, rgba(176, 60, 196, 0.6) 23.6%, rgba(192, 55, 173, 0.4) 35.4%, rgba(217, 46, 116, 0) 59%), radial-gradient(100% 100% at 10% 100%, #fdd575 0%, #fcc06a 14.29%, #fbaa63 28.57%, #f99262 42.86%, #f77868 57.14%, #f55973 71.43%, #d22e8c 100%)",
                  }}
                >
                  <FaInstagram className="w-auto h-full object-contain text-white" />
                </div>

                <p>Instagram Basmasesa</p>
              </motion.div>
              <div
                className="w-full h-full absolute rounded-full top-[6px] lg:top-2 left-1 lg:left-[6px]"
                style={{
                  background:
                    "linear-gradient(163deg, #4964da 0%, rgba(146, 73, 221, 0.8) 11.8%, rgba(176, 60, 196, 0.6) 23.6%, rgba(192, 55, 173, 0.4) 35.4%, rgba(217, 46, 116, 0) 59%), radial-gradient(100% 100% at 10% 100%, #fdd575 0%, #fcc06a 14.29%, #fbaa63 28.57%, #f99262 42.86%, #f77868 57.14%, #f55973 71.43%, #d22e8c 100%)",
                }}
              ></div>
            </a>

            <a
              href="https://wa.me/6281553046054?text=Hallo%20Basmasesa!"
              target="_blank"
              rel="noreferrer"
              className="relative flex"
            >
              <motion.div
                whileHover={{
                  x: 2,
                  y: 3,
                }}
                className="relative flex items-center justify-center w-full p-5 text-sm sm:text-lg font-semibold bg-sky-50 rounded-full z-[10] shadow-xl"
              >
                <div
                  className="absolute flex p-2 justify-center items-center h-11 w-11 sm:h-12 sm:w-12 rounded-full left-2 sm:left-3 absolute-y-center"
                  style={{
                    background: "#26D366",
                  }}
                >
                  <FaWhatsapp className="w-auto h-full object-contain text-white" />
                </div>

                <p>Whatsapp Basmasesa</p>
              </motion.div>
              <div
                className="w-full h-full absolute rounded-full top-[6px] lg:top-2 left-1 lg:left-[6px]"
                style={{
                  background: "#26D366",
                }}
              ></div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default LinkPage;
