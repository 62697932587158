import React, { useEffect, useState } from "react";
import "./PortfolioDetails.scss";
import Layout from "../components/Layout";
import PortfolioData from "../data/portfolio.json";
import { useParams } from "react-router-dom";
import { Carousel } from "react-carousel-minimal";
import { Breadcrumb } from "../components/Breadcrumb";
import NotFound from "./NotFound";
import LoadingSpinner from "../components/LoadingSpinner";
import { Helmet } from "react-helmet-async";

const PortfolioDetails = () => {
  const [data, setData] = useState([]);
  const [dataService, setDataService] = useState([]);

  const tabletBreakpoint = 640;
  const laptopBreakpoint = 1024;
  const pcBreakpoint = 1536;

  const { service_id, portfolio_id } = useParams();

  const carouselHeight = () => {
    let height;

    if (window.innerWidth >= laptopBreakpoint) {
      height = "75vh";
    } else if (
      window.innerWidth >= tabletBreakpoint &&
      window.innerWidth < laptopBreakpoint
    ) {
      height = "40vh";
    } else if (window.innerWidth < tabletBreakpoint) {
      height = "50vh";
    }

    console.log(height);
    return height;
  };

  useEffect(() => {
    let filterData = PortfolioData.filter((item) => item.slug === service_id);
    let filterPortfolio;

    if (filterData.length > 0) {
      filterPortfolio = filterData[0].portfolio.filter(
        (item) => item.slug === portfolio_id
      );

      // handle error or if portfolio is not found
      if (filterPortfolio.length > 0 && filterPortfolio !== null) {
        setDataService(filterData[0]);
        setData(filterPortfolio[0]);
      } else {
        setDataService(null);
        setData(null);
      }
    } else {
      setDataService(null);
      setData(null);
    }
  }, [portfolio_id, service_id]);

  const captionStyle = {
    fontSize: window.innerWidth > laptopBreakpoint ? "1.6em" : "2em",
    fontWeight: "bold",
  };
  const slideNumberStyle = {
    fontSize: "20px",
    fontWeight: "bold",
  };

  if (data === null && dataService === null) {
    return <NotFound />;
  }

  if (data.length < 1 && dataService.length < 1) {
    return (
      <Layout active_nav="portfolio">
        <div className="pfDetails text-light">
          <header className="w-full flex flex-col items-center">
            <div className="pfDetails__carousel w-full">
              <LoadingSpinner />
            </div>
          </header>
        </div>
      </Layout>
    );
  }

  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href={`https://basmasesa.com/portfolio/${service_id}/${portfolio_id}`}
        />
        <title>{`${data.title} - Basma | Event, Exhibition & Interior`}</title>
        <meta
          name="description"
          content="Basmasesa is a very experienced contractor, We have some amazing portfolio here and always be updated!"
        />
        <meta
          name="keywords"
          content={`events, exhibition, interior, kontraktor, contractor, booth, event booth, design interior, booth murah, ${data.title}, pameran, stand booth`}
        />
        <meta
          property="og:title"
          content={`${data.title} - Basma | Event, Exhibition & Interior`}
        />
        <meta
          property="og:description"
          content="Basmasesa is a very experienced contractor, We have some amazing portfolio here and always be updated!"
        />
        <meta
          property="og:url"
          content={`https://basmasesa.com/portfolio/${service_id}/${portfolio_id}`}
        />
      </Helmet>

      <Layout active_nav="portfolio">
        <Breadcrumb
          pages={[
            {
              title: "Services",
              link: `/services`,
            },
            {
              title: "Portfolio",
              link: "/portfolio",
            },
            {
              title: dataService.service,
              link: `/portfolio`,
            },
            {
              title: data.title,
              link: `/portfolio/${service_id}/${portfolio_id}`,
            },
          ]}
        />
        <div className="pfDetails text-light">
          <header className="w-full flex flex-col items-center lg:w-9/12 lg:mx-auto">
            <div className="pfDetails__carousel w-full">
              <Carousel
                data={data.images}
                time={8000}
                width="100%"
                height={carouselHeight()}
                captionStyle={captionStyle}
                radius="10px"
                slideNumber={true}
                slideNumberStyle={slideNumberStyle}
                captionPosition="bottom"
                automatic={true}
                dots={true}
                pauseIconColor="white"
                pauseIconSize="40px"
                slideBackgroundColor="darkgrey"
                slideImageFit="cover"
                thumbnails={true}
                thumbnailWidth={
                  window.innerWidth > laptopBreakpoint &&
                  window.innerWidth < pcBreakpoint
                    ? "90vw"
                    : "100vw"
                }
                style={{
                  textAlign: "center",
                  maxWidth: "100%",
                  maxHeight: carouselHeight(),
                }}
              />
            </div>
          </header>
          <main className="pfDetails__main w-full flex flex-col space-y-6 text-light text-lg font-medium sm:text-2xl lg:w-9/12 lg:mx-auto lg:leading-snug lg:text-1.2vw lg:mt-20 lg:space-y-7 2xl:text-1.25vw 2xl:leading-snug">
            <div className="w-full flex flex-col space-y-4 sm:space-y-5 lg:space-y-2">
              <h1 className="text-xl font-bold sm:text-3xl lg:text-3xl">
                {data.title}
              </h1>
            </div>

            <div className="w-full flex flex-col space-y-2 text-sm sm:text-xl lg:text-lg xl:text-xl">
              {data?.client !== "" && (
                <div className="w-full flex items-start">
                  <h3 className="w-20 sm:w-36 lg:w-40 shrink-0 font-bold">
                    Client
                  </h3>
                  <div className="flex w-full space-x-1 sm:space-x-2 lg:space-x-3">
                    <span>:</span>
                    <p>{data.client}</p>
                  </div>
                </div>
              )}

              {data?.location !== "" && (
                <div className="w-full flex items-start">
                  <h4 className="w-20 sm:w-36 lg:w-40 shrink-0 font-bold">
                    Location
                  </h4>
                  <div className="flex w-full space-x-1 sm:space-x-2 lg:space-x-3">
                    <span>:</span>
                    <p>{data.location}</p>
                  </div>
                </div>
              )}

              {data?.area !== "" && (
                <div className="w-full flex items-start">
                  <h5 className="w-20 sm:w-36 lg:w-40 shrink-0 font-bold">
                    Area
                  </h5>
                  <div className="flex w-full space-x-1 sm:space-x-2 lg:space-x-3">
                    <span>:</span>
                    <p>{data.area}</p>
                  </div>
                </div>
              )}
            </div>
          </main>
        </div>
      </Layout>
    </>
  );
};

export default PortfolioDetails;
