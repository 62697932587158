import { Route, Routes } from "react-router-dom";
import "./App.scss";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import Portfolio from "./pages/Portfolio";
import PortfolioDetails from "./pages/PortfolioDetails";
import Services from "./pages/Services";
import LinkPage from "./pages/LinkPage";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/services" element={<Services />} />

      {/* Portfolio Page */}
      <Route path="/portfolio" element={<Portfolio />} />
      <Route
        path="/portfolio/:service_id/:portfolio_id"
        element={<PortfolioDetails />}
      />

      {/* Link Page */}
      <Route path="/link" element={<LinkPage />} />

      <Route path="/contact" element={<Contact />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
