import React, { useState } from "react";
import "./Navbar.scss";
import { MdClose, MdMenu } from "react-icons/md";
import { Link } from "react-router-dom";

const Navbar = ({ active_nav }) => {
  const [show, setShow] = useState(false);

  function toggleShow() {
    setShow(!show);
  }

  return (
    <nav className="navbar shadow-xl">
      <Link to="/" className="navbar__logo">
        <img src="/images/basma-logo-long.png" alt="Logo" />
      </Link>

      <div className="navbar__list">
        <MdMenu
          onClick={toggleShow}
          className="icon-menu text-3xl sm:text-5xl lg:hidden"
        />
        <div className="hidden lg:flex lg:space-x-14 lg:text-light lg:font-bold lg:text-1.1vw 2xl:text-1.2vw">
          <Link
            to="/"
            className={`${
              active_nav === "home" ? "text-primary" : "text-light"
            }`}
          >
            Home
          </Link>
          <Link
            to="/services"
            className={`${
              active_nav === "services" ? "text-primary" : "text-light"
            }`}
          >
            Services
          </Link>
          <Link
            to="/portfolio"
            className={`${
              active_nav === "portfolio" ? "text-primary" : "text-light"
            }`}
          >
            Portfolio
          </Link>
          <Link
            to="/contact"
            className={`${
              active_nav === "contact" ? "text-primary" : "text-light"
            }`}
          >
            Contact
          </Link>
        </div>
      </div>

      <div
        className={`navbar__offcanvas lg:hidden ${
          show ? "offcanvas-show" : "offcanvas-hidden"
        } shadow-xl`}
      >
        <div className="navbar__offcanvas-header">
          <MdClose
            onClick={toggleShow}
            className="icon-close text-3xl sm:text-5xl"
          />
        </div>
        <div className="flex flex-col text-light font-semibold p-5 space-y-6 text-6vw sm:p-8 sm:space-y-10 sm:text-2xl">
          <Link
            to="/"
            className={`${
              active_nav === "home" ? "text-primary" : "text-light"
            }`}
          >
            Home
          </Link>
          <Link
            to="/services"
            className={`${
              active_nav === "services" ? "text-primary" : "text-light"
            }`}
          >
            Services
          </Link>
          <Link
            to="/portfolio"
            className={`${
              active_nav === "portfolio" ? "text-primary" : "text-light"
            }`}
          >
            Portfolio
          </Link>
          <Link
            to="/contact"
            className={`${
              active_nav === "contact" ? "text-primary" : "text-light"
            }`}
          >
            Contact
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
