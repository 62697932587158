import React from "react";
import Layout from "../components/Layout";
import AboutUs from "../section/AboutUs";
import Clients from "../section/Clients";
import Hero from "../section/Hero";
import SomeWork from "../section/SomeWork";
import VisionMission from "../section/VisionMission";
import { Helmet } from "react-helmet-async";
// import ContactNow from "../section/ContactNow";
// import Testimonial from "../section/Testimonial";

const Home = () => {
  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://basmasesa.com/" />
        <title>Home - Basma | Event, Exhibition & Interior</title>
        <meta
          name="description"
          content="Basmasesa is your one-stop event, exhibition & interior contractor for all your needs and wants. Build your imagine design and make it happen."
        />
        <meta
          name="keywords"
          content="events, exhibition, interior, kontraktor, contractor, booth, event booth, tenant, booth murah, property, pameran, stand booth"
        />
        <meta
          property="og:title"
          content="Home - Basma | Event, Exhibition & Interior"
        />
        <meta
          property="og:description"
          content="Basmasesa is your one-stop event, exhibition & interior contractor for all your needs and wants. Build your imagine design and make it happen."
        />
        <meta property="og:url" content="https://basmasesa.com/" />
      </Helmet>

      <Layout active_nav="home">
        <Hero />
        <AboutUs />
        <VisionMission />
        <SomeWork />
        <Clients />
        {/* <Testimonial /> */}
        {/* <ContactNow /> */}
      </Layout>
    </>
  );
};

export default Home;
