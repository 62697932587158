import React, { Fragment } from "react";
import "./Breadcrumb.scss";
import { HiChevronRight } from "react-icons/hi";
import { Link } from "react-router-dom";

export const Breadcrumb = ({ pages, style, className }) => {
  const lastPage = pages.length - 1;

  return (
    <div style={style} className={`breadcrumb ${className ? className : ""}`}>
      {pages.map((item, index) => {
        return (
          <Fragment key={index}>
            {index !== 0 && index !== pages.length ? (
              <HiChevronRight
                className={`icon-chevron text-lg sm:text-xl lg:text-1.3vw shrink-0`}
              />
            ) : (
              <Fragment></Fragment>
            )}
            <Link
              to={item.link}
              className={`breadcrumb-item font-medium text-sm sm:text-xl lg:text-1.1vw 2xl:text-1.2vw ${
                index === lastPage ? "breadcrumb-active" : ""
              }`}
            >
              {item.title}
            </Link>
          </Fragment>
        );
      })}
    </div>
  );
};
