import { createContext, useState } from 'react';

const mainContext = createContext({
  service: 'All',
  setService: () => {},
});

export const MainContextProvider = ({ children }) => {
  const [service, setService] = useState('All');

  return (
    <mainContext.Provider
      value={{
        service,
        setService,
      }}
    >
      {children}
    </mainContext.Provider>
  );
};

export default mainContext;
