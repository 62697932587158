import React, { useContext } from "react";
import { HiChevronRight } from "react-icons/hi";
import { Link } from "react-router-dom";
import mainContext from "../context/MainContext";
import "./Card.scss";

export const TestiCard = ({
  index,
  image,
  name,
  description,
  status,
  style,
}) => {
  return (
    <div
      key={index}
      className="testiCard flex flex-col items-center space-y-4 border-2 border-gray-dark rounded-md text-light sm:space-y-7 lg:space-y-6 2xl:space-y-8"
      style={style ? style : {}}
    >
      <img src={image} alt="User Avatar" />
      <div className="flex flex-col items-center lg:space-y-1 2xl:space-y-3">
        <h5 className="font-bold text-xl tracking-wide sm:text-3xl lg:text-1.6vw 2xl:text-1.7vw">
          {name}
        </h5>
        <h6 className="font-semibold text-lg text-primary sm:text-2xl lg:text-1.2vw 2xl:text-1.3vw">
          {status}
        </h6>
      </div>
      <p className="font-medium text-md text-light text-center sm:text-xl lg:text-1vw 2xl:text-1.1vw">
        {description}
      </p>
    </div>
  );
};

export const ServiceCard = ({ index, title, description }) => {
  const ctx = useContext(mainContext);

  return (
    <div
      key={index}
      className="serviceCard flex flex-col items-center justify-between bg-dark-darken rounded-md text-light"
    >
      <div className="flex flex-col items-center space-y-4 sm:space-y-6 lg:space-y-8 2xl:space-y-10">
        <h3 className="text-2xl font-bold sm:text-4xl lg:text-2vw 2xl:text-2.1vw">
          {title}
        </h3>
        <p className="text-lg font-medium sm:text-2xl lg:text-1.05vw 2xl:text-1.2vw 2xl:leading-tight">
          {description}
        </p>
      </div>
      <Link onClick={() => ctx.setService(title)} to={`/portfolio`}>
        <button className="flex items-center space-x-1 border-b border-primary text-primary text-lg font-semibold sm:border-b-2 sm:text-2xl lg:text-1vw lg:hover:space-x-2 2xl:text-1.1vw">
          <span>View all portfolio</span>
          <HiChevronRight className="text-xl sm:text-2xl lg:text-1.2vw 2xl:text-1.25vw transition-all ease-out duration-200" />
        </button>
      </Link>
    </div>
  );
};

export const PortfolioCard = ({ index, data, service_slug }) => {
  return (
    <div
      key={index}
      className="portfolioCard w-full flex flex-col justify-between items-center bg-dark-darken rounded-md text-light mx-auto"
    >
      <div className="flex flex-col space-y-5 items-center sm:space-y-6">
        <div className="portfolioCard__image rounded-md">
          <img src={data.thumbnail_image} alt="Portfolio" />
        </div>
        <h3 className="text-xl font-bold text-center sm:text-3xl lg:text-1.5vw 2xl:text-1.6vw 2xl:leading-none">
          {data.title}
        </h3>
        <div className="w-full flex flex-col text-sm sm:text-2xl lg:text-xs xl:text-sm 2xl:text-base">
          {data.location !== "" && <p>{`- ${data.location}`}</p>}
          {data.area !== "" && <p>{`- ${data.area}`}</p>}
        </div>
      </div>
      <Link to={`/portfolio/${service_slug}/${data.slug}`}>
        <button className="flex items-center space-x-1 border-b border-primary text-primary text-lg font-semibold sm:border-b-2 sm:text-2xl lg:text-1.1vw lg:hover:space-x-2 2xl:text-1.2vw">
          <span>View details</span>
          <HiChevronRight className="text-xl sm:text-2xl lg:text-1.2vw 2xl:text-1.3vw transition-all ease-out duration-200" />
        </button>
      </Link>
    </div>
  );
};
