import React from "react";
import "./Footer.scss";
import { FaWhatsapp, FaInstagram } from "react-icons/fa";
// import { TbBrandTiktok } from "react-icons/tb";
import { Link } from "react-router-dom";

const Footer = ({ active_nav }) => {
  const phoneNumber = "6281553046054";
  const waMessage = "Hallo Basmasesa Contractor";

  return (
    <footer className="footer flex flex-col space-y-12 sm:space-y-14">
      <div className="w-7/12 flex mx-auto sm:w-6/12 lg:hidden">
        <img src="/images/basma-logo-and-title-short.png" alt="Basma Logo" />
      </div>
      <div className="w-full flex flex-col space-y-8 text-light text-lg font-medium sm:space-y-12 sm:text-2xl lg:text-1.1vw lg:flex-row lg:mx-auto lg:space-y-0 lg:items-center lg:justify-between 2xl:text-1.2vw">
        <div className="w-11/12 mx-auto flex flex-col items-start space-y-1 sm:space-y-4 lg:w-3/12 lg:mx-0">
          <h6 className="text-2xl font-bold pb-2 sm:text-4xl sm:pb-4 lg:text-1.6vw 2xl:text-1.7vw">
            Quick Page
          </h6>
          <Link
            to="/"
            className={`${
              active_nav === "home" ? "text-primary" : "text-light"
            }`}
          >
            Home
          </Link>
          <Link
            to="/services"
            className={`${
              active_nav === "services" ? "text-primary" : "text-light"
            }`}
          >
            Services
          </Link>
          <Link
            to="/portfolio"
            className={`${
              active_nav === "portfolio" ? "text-primary" : "text-light"
            }`}
          >
            Portfolio
          </Link>
          <Link
            to="/contact"
            className={`${
              active_nav === "contact" ? "text-primary" : "text-light"
            }`}
          >
            Contact
          </Link>
        </div>
        <div className="w-full flex flex-row justify-center space-x-10 text-3xl text-primary sm:space-x-14 sm:text-4xl lg:w-3/12 2xl:text-2.1vw 2xl:space-x-16">
          <a href={`https://wa.me/${phoneNumber}?text=${waMessage}`}>
            <FaWhatsapp />
          </a>
          <a
            href="https://www.instagram.com/basmasesa.kreasi/"
            target="_blank"
            rel="noreferrer"
          >
            <FaInstagram />
          </a>

          {/* <a
            href="https://www.tiktok.com/@basma.contractor"
            target="_blank"
            rel="noreferrer"
          >
            <TbBrandTiktok />
          </a> */}
        </div>
        <div className="hidden lg:flex lg:w-3/12">
          <img
            className="w-full h-auto"
            src="/images/basma-logo-long.png"
            alt="Basma Logo"
          />
        </div>
      </div>
      <p className="w-full flex justify-center text-light text-lg text-center font-medium sm:text-2xl lg:text-1.1vw 2xl:text-1.2vw">
        Copyright &copy; 2023 PT. Basmasesa Adikreasi. All Rights Reserved
      </p>
    </footer>
  );
};

export default Footer;
