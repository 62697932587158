import React, { useRef } from "react";
import "./Services.scss";
import Layout from "../components/Layout";
import { ServiceCard } from "../components/Card";
import ServicesData from "../data/services.json";
import { Breadcrumb } from "../components/Breadcrumb";
import { useInView } from "framer-motion";
import { Helmet } from "react-helmet-async";

const animation = (isInView, delay) => {
  let style = {
    transform: isInView ? "none" : "translateX(50px)",
    WebkitTransform: isInView ? "none" : "translateX(50px)",
    MozTransform: isInView ? "none" : "translateX(50px)",
    msTransform: isInView ? "none" : "translateX(50px)",
    OTransform: isInView ? "none" : "translateX(50px)",
    opacity: isInView ? 1 : 0,
    transition: `all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) ${delay + 0.3}s`,
  };
  return style;
};

const animation2 = (isInView, delay) => {
  let style = {
    transform: isInView ? "none" : "translateY(50px)",
    WebkitTransform: isInView ? "none" : "translateY(50px)",
    MozTransform: isInView ? "none" : "translateY(50px)",
    msTransform: isInView ? "none" : "translateY(50px)",
    OTransform: isInView ? "none" : "translateY(50px)",
    opacity: isInView ? 1 : 0,
    transition: `all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) ${delay + 0.3}s`,
  };
  return style;
};

const Services = () => {
  // Animation
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://basmasesa.com/services" />
        <title>Services - Basma | Event, Exhibition & Interior</title>
        <meta
          name="description"
          content="Basmasesa is a very experienced contractor, We offer a comprehensive service that makes your dream event come to life!"
        />
        <meta
          name="keywords"
          content="events, exhibition, interior, kontraktor, contractor, booth, event booth, design interior, booth murah, property, pameran, stand booth"
        />
        <meta
          property="og:title"
          content="Services - Basma | Event, Exhibition & Interior"
        />
        <meta
          property="og:description"
          content="Basmasesa is a very experienced contractor, We offer a comprehensive service that makes your dream event come to life!"
        />
        <meta property="og:url" content="https://basmasesa.com/services" />
      </Helmet>

      <Layout active_nav="services">
        <Breadcrumb
          pages={[
            {
              title: "Home",
              link: "/",
            },
            {
              title: "Services",
              link: `/services`,
            },
          ]}
        />
        <div ref={ref} className="services text-light">
          <header className="w-10/12 flex flex-col items-center space-y-4 sm:space-y-6 sm:w-11/12 sm:mx-auto 2xl:space-y-10">
            <h1
              className="text-4xl font-bold sm:text-6xl lg:text-3vw"
              style={animation(isInView, 0)}
            >
              Our Services
            </h1>
            <p
              className="text-base text-center font-medium sm:text-2xl lg:text-1.5vw 2xl:text-1.55vw"
              style={animation(isInView, 0.25)}
            >
              Basmasesa is a very experienced contractor,
              <br />
              We offer a comprehensive service that makes your dream event come
              to life!
            </p>
          </header>
          <main className="services__main w-full flex flex-col space-y-6 sm:space-y-10 sm:items-center lg:flex-row lg:flex-wrap lg:space-y-0 lg:justify-evenly lg:items-start">
            {ServicesData.map((service, index) => {
              return (
                <div
                  key={index}
                  style={animation2(isInView, 0.25 * (index + 1) + 0.25)}
                >
                  <ServiceCard
                    index={index}
                    title={service.title}
                    description={service.description}
                    slug={service.slug}
                  />
                </div>
              );
            })}
          </main>
        </div>
      </Layout>
    </>
  );
};

export default Services;
