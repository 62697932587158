import React from "react";
import "./Contact.scss";
import { FaInstagram, FaWhatsapp } from "react-icons/fa";
// import { TbBrandTiktok } from "react-icons/tb";
import { Breadcrumb } from "../components/Breadcrumb";
import Layout from "../components/Layout";
// import ContactNow from "../section/ContactNow";
import { HiOutlineMail } from "react-icons/hi";
import { Helmet } from "react-helmet-async";

const Contact = () => {
  const phoneNumber = "6281553046054";
  const waMessage = "Hallo Basmasesa Contractor";

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://basmasesa.com/contact" />
        <title>Contact - Basma | Event, Exhibition & Interior</title>
        <meta
          name="description"
          content="Keep in touch on us. You can reach us by Email, Whatsapp and stay updated on our Instagram!"
        />
        <meta
          name="keywords"
          content="events, exhibition, interior, kontraktor, contractor, booth, event booth, design interior, booth murah, property, instagram kontraktor, stand booth"
        />
        <meta
          property="og:title"
          content="Contact - Basma | Event, Exhibition & Interior"
        />
        <meta
          property="og:description"
          content="Keep in touch on us. You can reach us by Email, Whatsapp and stay updated on our Instagram!"
        />
        <meta property="og:url" content="https://basmasesa.com/contact" />
      </Helmet>

      <Layout active_nav="contact">
        <Breadcrumb
          pages={[
            {
              title: "Home",
              link: "/",
            },
            {
              title: "Contact",
              link: `/contact`,
            },
          ]}
        />
        <div className="contact text-light">
          {/* <header className="contact__header w-full flex justify-center">
          <h1 className="text-4xl text-light font-bold tracking-wide sm:text-6xl lg:text-3vw">
            Contact Us
          </h1>
        </header> */}
          <main className="contact__main w-full flex flex-col space-y-10 text-light text-lg font-medium sm:space-y-14 sm:text-2xl lg:text-1.2vw lg:flex-row lg:flex-wrap lg:justify-between lg:space-y-0 2xl:text-1.3vw">
            {/* <div className="w-full 2xl:pb-5">
            <ContactNow hidden_title style={{ padding: 0 }} />
          </div> */}
            <div className="w-full flex flex-col space-y-5 text-light sm:space-y-6 lg:w-5/12 lg:space-y-8 2xl:space-y-10">
              <h3 className="text-xl font-bold sm:text-3xl lg:text-2.1vw 2xl:text-2.3vw">
                Visit Our Office
              </h3>
              <div className="contact__main-maps w-full font-medium">
                <iframe
                  title="Office Address"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.5449650789337!2d106.81922111417748!3d-6.323338095423795!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69eddee7b263bd%3A0x1642f88af852afb5!2sJl.%20Maninjo%20No.75%2C%20RT.1%2FRW.5%2C%20Jagakarsa%2C%20Kec.%20Jagakarsa%2C%20Kota%20Jakarta%20Selatan%2C%20Daerah%20Khusus%20Ibukota%20Jakarta%2012730!5e0!3m2!1sid!2sid!4v1666173990081!5m2!1sid!2sid"
                  allowfullscreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
              <address>
                Jalan Maninjo No.75 Jagakarsa, Jakarta Selatan, DKI Jakarta
                12620
              </address>
            </div>
            <div className="w-full flex flex-col space-y-5 text-light sm:space-y-7 lg:w-5/12 2xl:space-y-8">
              <h3 className="text-xl font-bold sm:text-3xl lg:text-2.1vw 2xl:text-2.3vw">
                You Can Reach Us On
              </h3>
              <div className="flex flex-col space-y-5 sm:space-y-7">
                <a
                  href={`mailto:basma.contractor@gmail.com`}
                  className="flex flex-row items-center space-x-4 text-light sm:space-x-5 lg:space-x-3 2xl:space-x-4"
                >
                  <HiOutlineMail className="text-2xl sm:text-3xl lg:text-1.4vw 2xl:text-1.55vw" />
                  <p>basmasesaadhikreasi@gmail.com</p>
                </a>

                <a
                  href={`https://wa.me/${phoneNumber}?text=${waMessage}`}
                  className="flex flex-row items-center space-x-4 text-light sm:space-x-5 lg:space-x-3 2xl:space-x-4"
                >
                  <FaWhatsapp className="text-2xl sm:text-3xl lg:text-1.4vw 2xl:text-1.55vw" />
                  <p>+62 81553046054</p>
                </a>

                <a
                  href="https://www.instagram.com/basmasesa.kreasi/"
                  className="flex flex-row items-center space-x-4 text-light sm:space-x-5 lg:space-x-3 2xl:space-x-4"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaInstagram className="text-2xl sm:text-3xl lg:text-1.4vw 2xl:text-1.55vw" />
                  <p>@basmasesa.kreasi</p>
                </a>

                {/* <a
                href="https://www.tiktok.com/@basma.contractor"
                className="flex flex-row items-center space-x-4 text-light sm:space-x-5 lg:space-x-3 2xl:space-x-4"
                target="_blank"
                rel="noreferrer"
              >
                <TbBrandTiktok className="text-2xl sm:text-3xl lg:text-1.4vw 2xl:text-1.55vw" />
                <p>@basmasesa.contractor</p>
              </a> */}
              </div>
            </div>
          </main>
        </div>
      </Layout>
    </>
  );
};

export default Contact;
