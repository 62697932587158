import React, { useContext, useEffect, useRef, useState } from "react";
import "./Portfolio.scss";
import Layout from "../components/Layout";
import { PortfolioCard } from "../components/Card";
import ServiceData from "../data/services.json";
import PortfolioData from "../data/portfolio.json";
import { HiChevronDown } from "react-icons/hi";
import mainContext from "../context/MainContext";
import { Breadcrumb } from "../components/Breadcrumb";
import { useInView } from "framer-motion";
import { Helmet } from "react-helmet-async";

const animation = (isInView, delay) => {
  let style = {
    transform: isInView ? "none" : "translateX(50px)",
    WebkitTransform: isInView ? "none" : "translateX(50px)",
    MozTransform: isInView ? "none" : "translateX(50px)",
    msTransform: isInView ? "none" : "translateX(50px)",
    OTransform: isInView ? "none" : "translateX(50px)",
    opacity: isInView ? 1 : 0,
    transition: `all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) ${delay + 0.3}s`,
  };
  return style;
};

const animation2 = (isInView, delay) => {
  let style = {
    transform: isInView ? "none" : "translateY(50px)",
    WebkitTransform: isInView ? "none" : "translateY(50px)",
    MozTransform: isInView ? "none" : "translateY(50px)",
    msTransform: isInView ? "none" : "translateY(50px)",
    OTransform: isInView ? "none" : "translateY(50px)",
    opacity: isInView ? 1 : 0,
    transition: `all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) ${delay + 0.3}s`,
  };
  return style;
};

const Portfolio = () => {
  const ctx = useContext(mainContext);

  // Animation
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const [dropdown, setDropdown] = useState(false);
  const [data, setData] = useState(PortfolioData);

  const showDropdown = "transform opacity-100 scale-100 z-10 ease-out";
  const hiddenDropdown = "transform opacity-0 scale-95 -z-10 ease-out";

  const toggleDropdown = () => {
    setDropdown(!dropdown);
  };

  const handleService = (service_option) => {
    ctx.setService(service_option);
    setDropdown(!dropdown);
  };

  useEffect(() => {
    if (ctx.service === "All") {
      setData(PortfolioData);
    } else {
      const filteredData = PortfolioData.filter(
        (serv) => serv.service === ctx.service
      );
      setData(filteredData);
    }
  }, [data, ctx.service]);

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://basmasesa.com/portfolio" />
        <title>Portfolio - Basma | Event, Exhibition & Interior</title>
        <meta
          name="description"
          content="Basmasesa is a very experienced contractor, We have some amazing portfolio here and always be updated!"
        />
        <meta
          name="keywords"
          content="events, exhibition, interior, kontraktor, contractor, booth, event booth, design interior, booth murah, property, pameran, stand booth"
        />
        <meta
          property="og:title"
          content="Portfolio - Basma | Event, Exhibition & Interior"
        />
        <meta
          property="og:description"
          content="Basmasesa is a very experienced contractor, We have some amazing portfolio here and always be updated!"
        />
        <meta property="og:url" content="https://basmasesa.com/portfolio" />
      </Helmet>

      <Layout active_nav="portfolio">
        <Breadcrumb
          pages={[
            {
              title: "Home",
              link: "/",
            },
            {
              title: "Services",
              link: `/services`,
            },
            {
              title: "Portfolio",
              link: `/portfolio`,
            },
          ]}
        />
        <div ref={ref} className="portfolio text-light">
          <header className="w-full flex flex-col items-center">
            <h1
              className="text-4xl font-bold sm:text-6xl lg:text-3vw"
              style={animation(isInView, 0)}
            >
              Our Portfolio
            </h1>
          </header>
          <main className="portfolio__main w-full flex flex-col space-y-6 sm:space-y-10">
            <div className="text-dark" style={animation(isInView, 0.25)}>
              <div className="w-10/12 flex flex-row items-center space-x-5 mx-auto sm:space-x-6 lg:w-4/12 lg:mx-0">
                <h4 className="text-light text-xl font-bold sm:text-3xl lg:text-1.5vw 2xl:text-1.6vw">
                  Service&nbsp;:
                </h4>
                <div className="w-full relative inline-block text-left lg:w-8/12">
                  <div>
                    <button
                      type="button"
                      className="inline-flex w-full justify-between items-center rounded-md border border-gray bg-dark-darken px-4 py-2 text-base font-semibold text-light shadow-sm hover:border-gray-dark focus:outline-none sm:text-2xl lg:text-1.2vw lg:px-6 lg:py-2 2xl:py-4"
                      id="menu-button"
                      onClick={toggleDropdown}
                    >
                      {ctx.service}
                      <HiChevronDown className="-mr-1 ml-2 h-5 w-5 sm:h-7 sm:w-7 lg:h-5 lg:w-5" />
                    </button>
                  </div>

                  <div
                    className={`absolute left-0 mt-2 w-110% origin-top-right rounded-md bg-dark-darken shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ${
                      dropdown ? showDropdown : hiddenDropdown
                    } transition-all duration-300`}
                  >
                    <div>
                      <span
                        onClick={() => handleService("All")}
                        className={`block cursor-pointer px-4 py-2 text-base font-semibold hover:bg-dark-darken-hover sm:px-6 sm:py-3 sm:text-2xl lg:text-1.2vw 2xl:py-4 ${
                          ctx.service === "All"
                            ? "bg-dark-darken-hover text-primary"
                            : "text-light"
                        }`}
                      >
                        All
                      </span>
                      {ServiceData.map((item, index) => {
                        return (
                          <span
                            key={index}
                            onClick={() => handleService(item.title)}
                            className={`block cursor-pointer px-4 py-2 text-base font-semibold hover:bg-dark-darken-hover sm:px-6 sm:py-3 sm:text-2xl lg:text-1.2vw 2xl:py-4 ${
                              ctx.service === item.title
                                ? "bg-dark-darken-hover text-primary"
                                : "text-light"
                            }`}
                          >
                            {item.title}
                          </span>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="portfolio__main-list text-light flex flex-col space-y-14 sm:space-y-20 lg:space-y-10">
              {data === null || data.length === 0 ? (
                <div className="flex justify-center items-center">
                  <h2>Maaf data belum ada.</h2>
                </div>
              ) : (
                data.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="ctx.service w-full flex flex-col items-start space-y-6 sm:space-y-8"
                    >
                      <h2
                        className="service__title mx-auto text-3xl font-bold tracking-wide border-b-2 border-primary sm:text-5xl sm:pb-1 lg:text-2.1vw 2xl:border-b-4 2xl:pb-0 2xl:leading-tight"
                        style={animation2(isInView, 0.25 * (index + 1) + 0.25)}
                      >
                        {item.service}
                      </h2>
                      <div className="service__list w-full flex flex-col items-center space-y-6 sm:space-y-8 sm:items-center lg:space-y-0 lg:flex-row lg:flex-wrap lg:items-start lg:justify-between">
                        {item.portfolio === null ||
                        item.portfolio?.length === 0 ? (
                          <div className="w-full flex justify-center items-center text-base sm:text-lg lg:text-xl font-semibold">
                            <h2>{`Maaf portfolio ${item?.service} belum ada.`}</h2>
                          </div>
                        ) : (
                          item.portfolio.map((portfolio, index) => {
                            return (
                              <div
                                key={portfolio.portfolio_id}
                                style={animation2(
                                  isInView,
                                  0.25 * (index + 1) + 0.25
                                )}
                              >
                                <PortfolioCard
                                  index={index}
                                  data={portfolio}
                                  service_slug={item.slug}
                                />
                              </div>
                            );
                          })
                        )}
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </main>
        </div>
      </Layout>
    </>
  );
};

export default Portfolio;
